import { adminArea, businessArea, customerArea, newBusinessArea, personalArea, processingArea, underwritingArea } from '@/router'
import { MyStore, useStore } from '@/store'
import { Component } from 'vue-property-decorator'
import BaseComponent from './BaseComponent'

@Component
export default class StateComponent extends BaseComponent {
    protected store!: MyStore

    get profile() {
        return this.store.profile
    }

    get activeUserId() {
        return this.profile?.id ?? null
    }

    get activeAgencyId() {
        return this.profile?.activeAgency?.id ?? null
    }

    get offer() {
        return this.store.offer
    }

    get settings() {
        return this.store.settings
    }

    get isDirectMarketing() {
        return !!this.profile?.isDirectMarketing
    }

    get isVitalityAgent() {
        return !!this.profile?.isVitalityAgent
    }

    get isInternalAgent() {
        return this.isVitalityAgent || this.isDirectMarketing
    }

    get isTelemed() {
        return !!this.profile?.isTelemed
    }

    get roles() {
        return this.profile?.roles
    }

    get isManager() {
        return this.roles?.some(r => r.endsWith('Manager'))
    }

    get isChannelManager() {
        return this.roles?.some(r => r === 'Channel Manager')
    }

    get isRegionalManager() {
        return this.roles?.some(r => r === 'Regional Manager')
    }

    get isAccountManager() {
        return this.roles?.some(r => r === 'Account Manager')
    }

    get isCustomer() {
        return this.roles?.every(r => r === 'Customer')
    }

    get isAdmin() {
        return this.roles?.some(r => r.endsWith('Admin'))
    }

    get isSystemAdmin() {
        return this.roles?.some(r => r === 'System Admin')
    }

    get isProperAdmin() {
        return this.isSystemAdmin || this.roles?.some(r => r === 'Vitality Admin')
    }

    get showInternalDetails() {
        return this.isProperAdmin || this.isInternalUser
    }

    get isServicingAdmin() {
        return this.roles?.some(r => r === 'Servicing Admin')
    }

    get isClaimsAdmin() {
        return this.roles?.some(r => r === 'Claims Admin')
    }

    get isPricingAdmin() {
        return this.roles?.some(r => r === 'Pricing Admin')
    }

    get isNewBusinessAdmin() {
        return this.roles?.some(r => r === 'New Business Admin')
    }

    get isUnderwritingAdmin() {
        return this.roles?.some(r => r === 'Underwriting Admin')
    }

    get isBrokerAdmin() {
        return this.roles?.some(r => r === 'Broker Admin')
    }

    get isBroker() {
        return this.roles?.some(r => r.startsWith('Broker'))
    }

    get isNewBusiness() {
        return this.roles?.some(r => r.startsWith('New Business'))
    }

    get isUnderwriting() {
        return this.roles?.some(r => r.startsWith('Underwriting'))
    }

    get isProcessing() {
        return this.roles?.some(r => r.startsWith('Processing'))
    }

    get isMarketing() {
        return this.roles?.some(r => r.startsWith('Marketing'))
    }

    get isInternalUser() {
        return (
            this.isServicingAdmin ||
            this.isProperAdmin ||
            this.isManager ||
            this.isNewBusiness ||
            this.isUnderwriting ||
            this.isPricingAdmin ||
            this.isClaimsAdmin ||
            this.isProcessing
        )
    }

    get areas() {
        const availableAreas: string[] = []

        if (this.roles?.some(r => r === 'Broker Agent')) {
            availableAreas.push(personalArea)
            //availableAreas.push(legacyArea)
            availableAreas.push(businessArea)
        }

        if (this.roles?.some(r => r === 'New Business Agent'))
            availableAreas.push(newBusinessArea)

        if (this.roles?.some(r => r === 'Underwriting Agent'))
            availableAreas.push(underwritingArea)

        if (this.roles?.some(r => r === 'Processing Agent'))
            availableAreas.push(processingArea)

        if (this.isCustomer)
            availableAreas.push(customerArea)

        if (this.isAdmin || this.isManager)
            availableAreas.push(adminArea)

        return availableAreas
    }

    get area() {
        return this.routePath.split('/')[1]
    }

    //// eslint-disable-next-line
    //protected async loadData(): Promise<void> { /**/ }

    //async beforeRouteEnter(to: Route, from: Route, next: (to?: RawLocation | false | ((vm: StateComponent) => void)) => void) {

    //    //next(async (vm) => {
    //    //    vm.setData(data, to)

    //    //    await vm.loaded()
    //    //    await vm.afterLoaded()
    //    //})
    //    const myStore = useStore<MyStore>(store)

    //    const disabledRoutes = myStore.settings?.disabledRoutes?.split(',') ?? []

    //    if (disabledRoutes.length > 0 && this.$route.matched.some(r => disabledRoutes.includes(r.path.replace(':id', '{id}'))))
    //        next('/disabled')
    //    else
    //        next(async (vm) => {
    //            await vm.loadData()

    //            await vm.loaded()
    //            await vm.afterLoaded()
    //        })

    //}

    beforeCreate() {
        this.store = useStore()
    }
}
