import Vue from 'vue'
import BootstrapVue, { VBTooltipPlugin, VBModalPlugin } from 'bootstrap-vue'

import '../../scss/bootstrap-vitality.scss'

Vue.use(BootstrapVue, {
    breakpoints: ['xs', 'sm', 'lg', 'xl', 'xxl'],

    BModal: {
        cancelVariant: 'outline-secondary'
    }
})

Vue.use(VBTooltipPlugin)
Vue.use(VBModalPlugin)
//Vue.use(SidebarPlugin)
