import { configure, extend } from 'vee-validate'
import validationLocale from 'vee-validate/dist/locale/en.json'
import * as validationRules from 'vee-validate/dist/rules'

import { accountNumber, dateAfter, dateBefore, dateBetween, dateFormat, email, lengthBetween, name, phoneNumber, postcode, sortCode } from '@/validation'

import { formatDate } from '@/utils'
import { ValidationRule } from 'vee-validate/dist/types/types'

configure({
    defaultMessage: 'The {_field_} is not valid'
})

for (const rule in validationRules)
    extend(rule, {
        ...(validationRules as { [elem: string]: ValidationRule })[rule], // add the rule
        message: (validationLocale.messages as { [elem: string]: string })[rule]?.replace(' field', '') // add its message
    })

extend('date_before', {
    ...dateBefore,
    message(field, params) {
        const max = params['max']
        const inclusion = params['inclusion']

        return `The ${field} must be ${inclusion ? 'on or' : ''} before ${formatDate(max)}`
    }
})

extend('date_after', {
    ...dateAfter,
    message(field, params) {
        const min = params['min'] as string
        const inclusion = params['inclusion'] as boolean

        return `The ${field} must be ${inclusion ? 'on or' : ''} after ${formatDate(min)}`
    }
})

extend('date_between', {
    ...dateBetween,
    message(field, params) {
        return `The ${field} must be ${params['inclusivity']?.startsWith('[') ? 'on or' : ''} after ${formatDate(params['min'])} and ${params['inclusivity']?.endsWith(']') ? 'on or' : ''} before ${formatDate(params['max'])}`
    }
})

extend('date_format', {
    ...dateFormat,
    message: 'The {_field_} must be in this date format: {format}'
})

extend('postcode', {
    ...postcode,
    message: 'The {_field_} must be a valid UK postcode'
})

extend('length_between', {
    ...lengthBetween,
    message: `The {_field_} must have e length between {min} and {max}`
})

extend('required', {
    ...validationRules.required,
    message(field, params) {
        return `The ${field} is required.${!params['allowFalse'] ? ' Please select to continue.' : ''}`
    }
})

extend('min', {
    ...validationRules.min,
    message: `The {_field_} must be at least {length} characters long`
})

extend('max', {
    ...validationRules.max,
    message: `The {_field_} must be less than {length} characters long`
})

extend('phone_number', {
    ...phoneNumber,
    message: `The {_field_} must be a valid phone number (e.g. +44 01234567890)`
})

extend('name', {
    ...name,
    message: `The {_field_} can only have the following special characters: "'" and "-"`
})

extend('sort_code', {
    ...sortCode,
    message: `The {_field_} must be a valid UK sort code (e.g. 12-34-56)`
})

extend('account_number', {
    ...accountNumber,
    message: `The {_field_} must be a valid UK account number (e.g. 12345678)`
})

extend('email', {
    ...email,
    message: `The {_field_} must be a valid email address (e.g. jane@domain.com)`
})