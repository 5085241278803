import { useArea } from '@/composables/useArea'
import { pdfArea } from '@/router'
import { defineComponent } from 'vue'

export default defineComponent({
    setup() {
        const area = useArea()

        if (area.isPdfPage.value)
            document.documentElement.classList.add(pdfArea)

        return {}
    },
    render() {
        return <router-view />
    }
})