import "core-js/stable"
import "regenerator-runtime/runtime"

// doesn't work with class props
//import 'reflect-metadata'

import '@/class-component-hooks'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'

if (process.env.NODE_ENV === 'development') {
    const appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: '9669303a-f6f6-42f7-89fc-cea763e1f1e3',
            enableAutoRouteTracking: true,
            enableCorsCorrelation: true,
            enableRequestHeaderTracking: true,
            enableResponseHeaderTracking: true,
            enableAjaxErrorStatusText: true,
            enableAjaxPerfTracking: true
        }
    })

    appInsights.loadAppInsights()
}

import Vue from 'vue'

import '@/plugins/bootstrap-vue'
import '@/plugins/vee-validate'

import { Plugin } from 'vue-fragment'
Vue.use(Plugin)

import App from '@/App'
import '@/registerServiceWorker'
import router from '@/router'
import store from '@/store'

Vue.config.productionTip = false

const app = new Vue({
    router,
    store,
    render: (h) => h(App)
})

app.$mount('#app')
