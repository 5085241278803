import type { RuleParamSchema } from 'vee-validate/dist/types/types'
import { parseDate } from '@/utils'
import { config } from '@/data/defaults.json'

const isValid = (value: string, format = config.dateFormat) =>
    !!parseDate(value, format)

const validate = (value: string, { format }: Record<string, any>) => {
    return isValid(value, format)
}

const params: RuleParamSchema[] = [
    {
        name: 'format'
    }
]

export { validate, isValid, params }

export default {
    validate,
    isValid,
    params
}