import type { IProfile, ISettings, Nullable, QueueType } from '@/models'
import Vue from 'vue'
import Vuex from 'vuex'
import { createVuexStore, Module, Mutation, State, useStore as getStore } from 'vuex-simple'
import { GridModule } from './modules/grid'

Vue.use(Vuex)

export class MyStore {

    @State()
    offer = true

    @State()
    queue: Nullable<QueueType> = null

    @State()
    profile: Nullable<IProfile> = null

    @State()
    settings: Nullable<ISettings> = null

    @Module()
    agencies = new GridModule()

    @Module()
    regions = new GridModule()

    @Module()
    underwritingQuestions = new GridModule()

    @Module()
    modules = new GridModule()

    @Module()
    moduleGroups = new GridModule()

    @Module()
    underwritingQuestionAnswers = new GridModule()

    @Module()
    underwritingRiskScores = new GridModule()

    @Module()
    medicalConditionGroups = new GridModule()

    @Module()
    accountManagers = new GridModule()

    @Module()
    healthcarePlans = new GridModule()

    @Module()
    healthcarePlansAdmin = new GridModule()

    @Module()
    quoteRequests = new GridModule()

    @Module()
    quoteRequestsAdmin = new GridModule()

    @Module()
    insurers = new GridModule()

    @Module()
    insurerHospitalLists = new GridModule()

    @Module()
    insurerModules = new GridModule()

    @Module()
    insurerModuleGroups = new GridModule()

    @Module()
    queryTypes = new GridModule()

    @Module()
    postcodeAreas = new GridModule()

    @Module()
    bundles = new GridModule()

    @Module()
    bundlesAdmin = new GridModule()

    @Module()
    insurerSchemes = new GridModule()

    @Module()
    products = new GridModule()

    @Module()
    templates = new GridModule()

    @Module()
    contentPages = new GridModule()

    @Module()
    users = new GridModule()

    @Module()
    customerNeeds = new GridModule()

    @Module()
    notifications = new GridModule()

    @Module()
    indicativeBusinessQuotes = new GridModule()

    @Module()
    indicativeBusinessQuotesAdmin = new GridModule()

    @Module()
    factFinds = new GridModule()

    @Module()
    factFindsAdmin = new GridModule()

    @Module()
    mtjCases = new GridModule()

    @Module()
    mtjCasesAdmin = new GridModule()

    @Module()
    personalQuotes = new GridModule()

    @Module()
    personalQuotesAdmin = new GridModule()

    @Module()
    personalApplications = new GridModule()

    @Module()
    personalApplicationsAdmin = new GridModule()

    @Module()
    businessQuotes = new GridModule()

    @Module()
    businessQuotesAdmin = new GridModule()

    @Module()
    businessApplications = new GridModule()

    @Module()
    businessApplicationsAdmin = new GridModule()

    @Module()
    processedBusinessApplications = new GridModule()

    @Mutation()
    mutateProfile(val: Nullable<IProfile>) {
        if (this.profile && val)
            Object.assign(this.profile, val)
        else
            this.profile = val
    }

    @Mutation()
    mutateSettings(val: Nullable<ISettings>) {
        if (this.settings && val)
            Object.assign(this.settings, val)
        else
            this.settings = val
    }

    @Mutation()
    mutateOffer(val: boolean) {
        this.offer = val
    }

    @Mutation()
    mutateQueue(val: Nullable<QueueType>) {
        this.queue = val
    }
}

const store = createVuexStore(new MyStore(), {
    strict: process.env.NODE_ENV !== 'production'
})

export const useStore = () =>
    getStore<MyStore>(store)

export default store