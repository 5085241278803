import { pdfArea } from "@/router"
import { isRoute } from "@/utils"
import { computed } from 'vue'
import { useRoute } from 'vue-router/composables'

export function useArea() {

    const route = useRoute()

    const isArea = (area: string) =>
        isRoute(route, `/${area}`)

    const isPdfPage = computed(() => isArea(pdfArea))

    //const isCustomerPage = computed(() => isArea(customerArea))

    //const isAdminPage = computed(() => isArea(adminArea))

    //const isPersonalPage = computed(() => isArea(personalArea))

    //const isBusinessPage = computed(() => isArea(businessArea))

    //const isLegacyPage = computed(() => isArea(legacyArea))

    //const isAdviserPage = computed(() => isPersonalPage.value || isBusinessPage.value)

    //const isNewBusinessPage = computed(() => isArea(newBusinessArea))

    //const isProcessingPage = computed(() => isArea(processingArea))

    //const isUnderwritingPage = computed(() => isArea(underwritingArea))

    //const pageIsReady = () => {
    //    document.dispatchEvent(new CustomEvent(config.pageReadyEvent))
    //}

    //const showModal = (message: string, title?: string) => {
    //    document.dispatchEvent(new CustomEvent('modal', { detail: { title, message } }))
    //}

    //const logOut = async () => {
    //    const response = await postAsync('/Account/LogOut')

    //    if (response && response.status !== 400)
    //        window.location.reload()
    //}

    //// bubble up
    //const triggerDataReset = () => {
    //    const vm = getCurrentInstance()

    //    vm?.proxy.$emit('reset')
    //}

    //const matchedRoutes = computed(() => route.matched.reverse())

    //const name = computed(() => route.meta?.entityName as string | undefined ?? '')

    //const apiUrl = computed(() => route.meta?.apiUrl as string | undefined ?? '')

    //const updateUrl = computed(() => route.meta?.updateUrl as string | undefined ?? '')

    //const routePath = computed(() => route.path)

    //const createNewPath = computed(() => `${routePath.value}/${newPath}`)

    return {
        isPdfPage,

        //isCustomerPage,
        //isAdminPage,
        //isPersonalPage,
        //isBusinessPage,
        //isLegacyPage,
        //isAdviserPage,
        //isNewBusinessPage,
        //isProcessingPage,
        //isUnderwritingPage,

        //isArea,
        //name,
        //matchedRoutes,
        //apiUrl,
        //updateUrl,
        //routePath,
        //createNewPath,
        //pageIsReady,
        //showModal,
        //logOut,
        //triggerDataReset
    }
}