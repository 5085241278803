import { Nullable } from '@/models'
import StateComponent from '@/models/StateComponent'
import { addScripts, setSettings } from '@/utils'
import { BModal } from 'bootstrap-vue'
import { Component } from 'vue-property-decorator'
import VueScrollTo from 'vue-scrollto'

@Component
export default class App extends StateComponent {

    modal = false

    title: Nullable<string> = null
    message: Nullable<string> = null

    registration: Nullable<ServiceWorkerRegistration> = null

    refreshing = false
    updateModal = false

    get showHeader() {
        return !!this.title
    }

    swUpdatedListener(event: Event) {
        // The new service worker is installed, but not yet active.
        // Store the ServiceWorkerRegistration instance for later use.
        this.registration = (event as CustomEvent<ServiceWorkerRegistration>).detail
        this.updateModal = true
    }

    refreshApp() {
        this.updateModal = false

        // Protect against missing registration.waiting.
        if (!this.registration || !this.registration.waiting)
            return

        this.registration.waiting.postMessage('skipWaiting')
    }

    modalListener(event: Event) {
        const detail = (event as CustomEvent).detail as { title?: string, message: string }

        this.title = detail.title ?? null
        this.message = detail.message

        this.modal = true
    }

    onModalClosed() {
        VueScrollTo.scrollTo('.is-invalid', 500, { offset: -30 })
    }

    async created() {
        await setSettings()

        addScripts()

        // Listen for swUpdated event and display refresh modal as required.
        document.addEventListener('swUpdated', this.swUpdatedListener, { once: true })

        document.addEventListener('modal', this.modalListener)

        // Refresh all open app tabs when a new service worker is installed.
        const serviceWorker = navigator.serviceWorker
        if (serviceWorker)
            serviceWorker.addEventListener('controllerchange', () => {
                if (this.refreshing)
                    return

                this.refreshing = true

                location.reload()
            })
    }

    render() {
        return (
            <div id="app" class="h-100">

                <router-view />

                <BModal
                    v-model={this.updateModal}
                    hideHeader
                    on-ok={this.refreshApp}>
                    A new version is available. Do you want to update now?
                </BModal>
                <BModal
                    v-model={this.modal}
                    hideHeader={!this.showHeader}
                    title={this.title}
                    okOnly
                    noCloseOnBackdrop
                    noCloseOnEsc
                    on-hidden={this.onModalClosed}>
                    {this.message}
                </BModal>
            </div>
        )
    }
}
